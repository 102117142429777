.item {
  display: flex;
  flex-direction: row;
  height: 60px;
  border-bottom: 1px solid #dfdfdf;
  color: #1b1b1b;
  &:hover {
    background: #dfdfdf;
  }
  &__number {
    flex: 0.25;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__play {
    font-size: clamp(5px, 5vw, 35px);
    color: #4e4e4e;
  }
  &__sound {
    flex: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__photo_default {
    background-image: url("../../graphics/default.png");
    background-size: contain;
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  &__photo {
    background-image: url("../../graphics/default.png");
    background-size: contain;
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  &__track {
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
  }
  &__track > p {
    text-align: left;
    font-size: clamp(8px, 2.5vw, 16px);
    padding: 0;
    margin: 0;
  }
  &__view {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.artist {
  display: flex;
  justify-content: space-between;
  background: #4e4e4e;
  padding: 20px 20px;
  color: #fff;
  border-radius: 5px;
}
.artist > p {
  font-size: clamp(5px, 3.5vw, 28px);
  padding: 0;
  margin: 0;
}

.all_item_img {
  margin-right: 10px;
}
