@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?loqa7r');
  src:  url('fonts/icomoon.eot?loqa7r#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?loqa7r') format('truetype'),
    url('fonts/icomoon.woff?loqa7r') format('woff'),
    url('fonts/icomoon.svg?loqa7r#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i, .icomoon-liga {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-play_arrow:before {
  content: "\e900";
}
.icon-play_circle_filled:before {
  content: "\e901";
}
.icon-skip_next:before {
  content: "\e902";
}
.icon-skip_previous:before {
  content: "\e903";
}
