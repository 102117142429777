.footer {
  background: #181818;
  border-top: 1px solid #3d3d3d;
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding-left: 30px;
  padding-right: 30px;
  color: #b3b3b3;
}

.info {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: start;
    height: 60px;
    width: 35vw;
  }
}

.player {
  height: 50px;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  &__prev {
    font-size: 30px;
  }
  &__play {
    font-size: 45px;
  }
  &__ff {
    font-size: 30px;
  }
}

.img {
  width: 35vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &__icon {
    background-image: url("../../graphics/default.png");
    background-size: contain;
    width: 50px;
    height: 50px;
  }
}

.timeline {
  display: flex;
  align-items: center;
  width: 100%;

  &__time {
    width: 45px;
  }
  &__input {
    color: #5b80b2;
    width: 100%;
    margin: 10px;
  }
}
